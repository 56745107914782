var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: 12 } }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "v-card",
              {
                staticClass: "mx-auto",
                attrs: { "max-width": "800px", flat: "" }
              },
              [
                _c("v-card-title", [_vm._v("Website / App Analyzer")]),
                _c(
                  "v-card-text",
                  [
                    _c("div", [
                      _vm._v(
                        "\n            Our end-to-end encryption is great at keeping accountability data private. But it also prevents us from learning of new apps/websites. By submitting websites/apps for analysis, we can learn of these new websites/apps and assign them a risk rating."
                      ),
                      _c("br"),
                      _c("br"),
                      _c("b", [
                        _vm._v(
                          "Please enable automatic submission below to improve our monitoring abilities."
                        )
                      ]),
                      _c("br")
                    ]),
                    _c(
                      "v-card",
                      { attrs: { outlined: "" } },
                      [
                        _c("v-card-title", [
                          _vm._v(
                            "\n              Web App Settings\n            "
                          )
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c("v-switch", {
                              attrs: {
                                label:
                                  "Automatically submit unknown apps/websites without asking"
                              },
                              on: { change: _vm.submitAllAutomaticallyChange },
                              model: {
                                value: _vm.submitAllAutomatically,
                                callback: function($$v) {
                                  _vm.submitAllAutomatically = $$v
                                },
                                expression: "submitAllAutomatically"
                              }
                            }),
                            _c("div", [
                              _vm._v(
                                "\n                Automatic submission will cause the app to submit unknown apps/websites for analysis automatically each time the account owner or report recipient logs in to check a monitoring report. This is the easiest way to ensure all apps/websites being used on monitored devices have been analyzed.\n              "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card",
                      { staticClass: "mt-2", attrs: { outlined: "" } },
                      [
                        _c("v-card-title", [_vm._v("Manual Submissions")]),
                        _c(
                          "v-card-text",
                          [
                            _c("v-textarea", {
                              attrs: {
                                loading: _vm.loading,
                                disabled: _vm.loading,
                                autofocus: "",
                                outlined: "",
                                "persistent-hint": true,
                                hint:
                                  "Seperate multiple domains with a new line.  Don't include the http(s) or anything after the domain (ie, no paths like reddit.com/nsfw).  Only the domain.",
                                label: "Website domains",
                                placeholder: "ie, google.com"
                              },
                              model: {
                                value: _vm.domain,
                                callback: function($$v) {
                                  _vm.domain = $$v
                                },
                                expression: "domain"
                              }
                            }),
                            _c("v-checkbox", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.submitting &&
                                    _vm.domain.indexOf("\n") === -1 &&
                                    _vm.appId.length === 0,
                                  expression:
                                    "!submitting && domain.indexOf('\\n') === -1 && appId.length === 0"
                                }
                              ],
                              attrs: {
                                disabled: _vm.loading,
                                label:
                                  "Check if website is a porn site.  Leave unchecked if it's a mostly or completely innocent site."
                              },
                              model: {
                                value: _vm.risk,
                                callback: function($$v) {
                                  _vm.risk = $$v
                                },
                                expression: "risk"
                              }
                            }),
                            _c("v-divider", { staticClass: "mt-0 mb-5" }),
                            _c("v-textarea", {
                              attrs: {
                                loading: _vm.loading,
                                disabled: _vm.loading,
                                outlined: "",
                                "persistent-hint": true,
                                hint:
                                  "Separate multiple app ids with a new line.  Case sensitive.",
                                label: "App ids / package names",
                                placeholder: "ie, com.android.chrome"
                              },
                              model: {
                                value: _vm.appId,
                                callback: function($$v) {
                                  _vm.appId = $$v
                                },
                                expression: "appId"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  loading: _vm.loading,
                                  disabled:
                                    _vm.loading ||
                                    (_vm.domain.length === 0 &&
                                      _vm.appId.length === 0) ||
                                    (_vm.domain.indexOf(".") === -1 &&
                                      _vm.appId.indexOf(".") === -1) ||
                                    _vm.domain.endsWith(".") ||
                                    _vm.appId.endsWith(".") ||
                                    _vm.domain.indexOf("/") !== -1
                                },
                                on: { click: _vm.send }
                              },
                              [_vm._v("Submit")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-snackbar",
                      {
                        model: {
                          value: _vm.showThankYou,
                          callback: function($$v) {
                            _vm.showThankYou = $$v
                          },
                          expression: "showThankYou"
                        }
                      },
                      [
                        _vm._v(
                          "\n            Thank you for helping to improve Truple!  We'll redirect you back to the home page.\n            "
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", icon: "" },
                            on: {
                              click: function($event) {
                                _vm.showThankYou = false
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("close")])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm.results.length > 0
        ? _c("v-col", { staticClass: "d-flex", attrs: { cols: 12 } }, [
            _c(
              "div",
              { staticClass: "mx-auto" },
              _vm._l(_vm.results, function(result) {
                return _c("div", { key: result.timestamp }, [
                  _vm._v(
                    "\n\n        " +
                      _vm._s(
                        (result.domain ? result.domain : result.appId) +
                          " assigned " +
                          (result.risk ? "high risk" : "low risk") +
                          " " +
                          (result.succeed ? "✔️" : "❌" + result.error)
                      ) +
                      "\n      "
                  )
                ])
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }