<template>
  <v-row>
    <v-col :cols="12">
      <div class="d-flex">
        <v-card max-width="800px" class="mx-auto" flat>
          <v-card-title>Website / App Analyzer</v-card-title>
          <v-card-text>
            <div>
              Our end-to-end encryption is great at keeping accountability data private. But it also prevents us from learning of new apps/websites. By submitting websites/apps for analysis, we can learn of these new websites/apps and assign them a risk rating.<br />
              <br /><b>Please enable automatic submission below to improve our monitoring abilities.</b><br />
            </div>
            <v-card outlined>
              <v-card-title>
                Web App Settings
              </v-card-title>
              <v-card-text>
                <v-switch v-model="submitAllAutomatically" @change="submitAllAutomaticallyChange" label="Automatically submit unknown apps/websites without asking" />
                <div>
                  Automatic submission will cause the app to submit unknown apps/websites for analysis automatically each time the account owner or report recipient logs in to check a monitoring report. This is the easiest way to ensure all apps/websites being used on monitored devices have been analyzed.
                </div>
              </v-card-text>
            </v-card>
            <v-card outlined class="mt-2">
              <v-card-title>Manual Submissions</v-card-title>
              <v-card-text>
                <v-textarea :loading="loading" :disabled="loading" autofocus outlined v-model="domain" :persistent-hint="true" hint="Seperate multiple domains with a new line.  Don't include the http(s) or anything after the domain (ie, no paths like reddit.com/nsfw).  Only the domain." label="Website domains" placeholder="ie, google.com" />
                <v-checkbox v-show="!submitting && domain.indexOf('\n') === -1 && appId.length === 0" :disabled="loading" v-model="risk" label="Check if website is a porn site.  Leave unchecked if it's a mostly or completely innocent site." />
                <v-divider class="mt-0 mb-5" />
                <v-textarea :loading="loading" :disabled="loading" outlined v-model="appId" :persistent-hint="true" hint="Separate multiple app ids with a new line.  Case sensitive." label="App ids / package names" placeholder="ie, com.android.chrome" />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="send" color="primary" :loading="loading" :disabled="loading || (domain.length === 0 && appId.length === 0) || (domain.indexOf('.') === -1 && appId.indexOf('.') === -1) || domain.endsWith('.') || appId.endsWith('.') || domain.indexOf('/') !== -1">Submit</v-btn>
              </v-card-actions>
            </v-card>
            <v-snackbar v-model="showThankYou">
              Thank you for helping to improve Truple!  We'll redirect you back to the home page.
              <v-btn color="primary" icon @click="showThankYou = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-snackbar>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
    <v-col :cols="12" v-if="results.length > 0" class="d-flex">
      <div class="mx-auto">
        <div v-for="result in results" :key="result.timestamp">

          {{`${result.domain ? result.domain : result.appId} assigned ${result.risk ? 'high risk' : 'low risk'} ${result.succeed ? '✔️' : '❌' + result.error}`}}
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImagesApi from "@/aws/images/ImagesApi";
import StoreConstants from "@/StoreConstants";
import Utils from "@/Utils";

export default {
  name: "RatingCorrection",
  data() {
    return {
      submitAllAutomatically: this.$store.state.submitAllAutomatically,
      showThankYou: false,
      hasShownThankYou: false,
      domain: '',
      appId: '',
      risk: false,
      results: [],
      isSubscribed: false,
      loading: false,
      submitting: false
    }
  },
  mounted() {
    if(this.$store.state.unknown) {
      const unknownWebsites = this.$store.state.unknown.sites
      if(unknownWebsites) {
        this.domain = unknownWebsites.join('\n')
      }
      const unknownApps = this.$store.state.unknown.apps
      if(unknownApps) {
        this.appId = unknownApps.join('\n')
      }

      if(this.$route.query.submit) {
        this.submitting = true
        this.send().finally(async () => {
          this.submitting = false
          this.loading = true
          await Utils.sleep(1000)
          this.$router.back()
        })
      }
    }
  },
  methods: {
    submitAllAutomaticallyChange() {
      this.$store.commit(StoreConstants.commits.setSubmitAllAutomatically, this.submitAllAutomatically)
    },
    async send() {
      this.loading = true

      if(this.domain) {
        let domains = this.domain.split('\n')
        for(let d of domains) {
          d = d.trim()
          if(!d) {
            continue
          }
          console.log(d)
          let result = null
          try {
            result = await ImagesApi.submitWebsiteCorrection(d, domains.length > 1 || this.submitting ? null : this.risk.toString())
            if(!result) {
              throw new Error('site is locked')
            }
            this.results.unshift({
              timestamp: Date.now(),
              domain: d,
              risk: result.data.risk,
              succeed: true
            })
          } catch(e) {
            this.results.unshift({
              timestamp: Date.now(),
              domain: d,
              risk: this.risk,
              succeed: false,
              error: e
            })
          }
          console.log(result)
        }
        this.domain = ''
      }


      if(this.appId) {
        let appIds = this.appId.split('\n')
        for(let a of appIds) {
          a = a.trim()
          if(!a) {
            continue
          }
          let result = null
          try {
            result = await ImagesApi.submitAppCorrection(a)
            if(!result) {
              throw new Error('app is locked')
            }
            this.results.unshift({
              timestamp: Date.now(),
              appId: a,
              risk: false,
              succeed: true
            })
          } catch(e) {
            this.results.unshift({
              timestamp: Date.now(),
              appId: a,
              risk: false,
              succeed: false,
              error: e
            })
          }
        }

        this.appId = ''
      }

      this.showThankYou = !this.hasShownThankYou && this.submitting
      this.hasShownThankYou = true

      await this.$store.commit(StoreConstants.commits.setUnknown, {
        sites: [],
        apps: []
      })
      await Utils.sleep(2500)
      await this.$store.dispatch(StoreConstants.actions.loadAccount)

      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
